input[type=radio]{
    margin: 0;
}

.formBilli{
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 10px;
    padding: 1%;
    align-items: center;
    justify-content: center;
}

.formItem{
    display: inline-flex;
    font-size: 1.2rem;
    padding: 5px;
    text-align: end;
}

.formItem label{
    float: left;
    width: 100%;
    margin-right: 10px;
}

input, select{
    float: left;
}

.radioButtons{
    width: 70%;
}

.radioButtons label{
    width: auto;
    margin-left: 10px;
}

.radioButtons input[type=radio]{
    margin-left: 10px;
}